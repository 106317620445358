import React, { useState, useEffect, useContext } from 'react';
import { fetchSavedConfiguration } from '../../threekit/backend';
import { handleInputSelect } from '../../config/eventListeners';
import toolkit from '../toolkit';
import {
  getFullConfiguration,
  getPresets,
  getTranslations,
  initializePlayer,
} from '../../threekit/player';

import AppContext from '../Context/AppContext';

const TemplateContainer = ({
  template: Template,
  title,
  subtitle,
  threekitConfig,
}) => {
  const [controller, setController] = useState([]);
  const [languageData, setLanguageData] = useState({});
  const [configuration, setConfiguration] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [presetsData, setPresetsData] = useState([]);

  const contextApp = useContext(AppContext);

  const getInitialParameters = () => {
    const urlParameter = window.location.search;
    const urlParams = new URLSearchParams(urlParameter);

    const initialSettings = {
      authToken:
        urlParams.has('authToken') === true
          ? urlParams.get('authToken')
          : threekitConfig.authToken,
      assetId:
        urlParams.has('assetId') === true
          ? urlParams.get('assetId')
          : threekitConfig.assetId,
      orgId:
        urlParams.has('orgId') === true
          ? urlParams.get('orgId')
          : threekitConfig.orgId,
      showAR:
        urlParams.has('showAR') === true
          ? urlParams.get('showAR') === 'true'
            ? true
            : false
          : false,
      showConfigurator:
        urlParams.has('showConfigurator') === true
          ? urlParams.get('showConfigurator') === 'true'
            ? true
            : false
          : false,
      stageId:
        urlParams.has('stageId') === true ? urlParams.get('stageId') : null,
      locale:
        urlParams.has('locale') === true
          ? urlParams.get('locale')
          : threekitConfig.defaultLocale,
      currency:
        urlParams.has('currency') === true
          ? urlParams.get('currency')
          : threekitConfig.currency,
      pricebookName:
        urlParams.has('pricebookName') === true
          ? urlParams.get('pricebookName')
          : threekitConfig.pricebookName,
      showSIF: threekitConfig.showSIF,
      initialConfiguration: threekitConfig.initialConfiguration,
    };
    return initialSettings;
  };

  const initialParameters = getInitialParameters();
  // eslint-disable-next-line no-unused-expressions
  initialParameters.authToken !== null &&
  initialParameters.assetId !== null &&
  initialParameters.orgId !== null
    ? (threekitConfig = initialParameters)
    : null;

  const contextData = {};
  const setContextByProp = (key, val) => {
    return Object.assign(contextData, { [key]: val });
  };

  const handleResetConfiguration = async (config) => {
    const compiledAttributes = await toolkit.player.compileAttributesData();

    const handleSubConfiguration = async (parent, assetId, config, level) => {
      const subcategoryName = Object.keys(config)[0];
      await window.threekitApi.api.scene.fetch(assetId);
      const subCatalog = window.threekitApi.api.scene.get({
        id: assetId,
      });
      const result = await toolkit.player.compileAttributesData(
        {},
        subCatalog.configurator.attributes
      );
      subCatalog.configurator.attributes.forEach((attr) => {
        if (result[attr.name]) {
          result[attr.name].parent = parent;
          result[attr.name].level = level;
          result[attr.name].configurator = compiledAttributes[parent]
            .configurator
            ? compiledAttributes[parent].configurator.getNestedConfigurator({
                type: 'Asset',
                name: parent,
              })
            : window.threekitApi.api.player.configurator.getNestedConfigurator({
                type: 'Asset',
                name: parent,
              });
          compiledAttributes[attr.name] = result[attr.name];
        }
      });
      const { assetId: subAssetId, configuration } = config[subcategoryName];
      if (configuration) {
        await handleSubConfiguration(
          subcategoryName,
          subAssetId,
          configuration,
          level + 1
        );
      }
    };

    for (const attrName of Object.keys(config)) {
      const { configuration, assetId } = config[attrName];
      if (configuration) {
        await handleSubConfiguration(attrName, assetId, configuration, 1);
      }
    }

    const templateData = await toolkit.template.prepTemplate(
      setContextByProp,
      contextApp.getPropConfiguration,
      config,
      compiledAttributes
    );
    await contextApp.setAppConfiguration(contextData);
    setController(templateData);
    setAttributes(compiledAttributes);
    const flatConfiguration = convert(config);
    await toolkit.player.getNewUpdateConfiguration(flatConfiguration);
    setConfiguration(getNewConfiguration(flatConfiguration));
  };

  const initPresets = async () => {
    let presetData = [];

    const presets = await getPresets(initialParameters.authToken);

    if (presets && presets.length) {
      presets.forEach((preset, index) => {
        fetchSavedConfiguration(
          preset.configurationId,
          initialParameters.authToken
        ).then(async (getConfigurationResult) => {
          if (getConfigurationResult) {
            presetData.push({
              config: getConfigurationResult.variant,
              thumbnailUrl: preset.thumbnailUrl,
            });
          }
          if (
            index === presets.length - 1 &&
            presetsData.length !== presets.length
          ) {
            setPresetsData(presetData);
          }
        });
      });
    }
  };

  useEffect(() => {
    (async () => {
      await initializePlayer({
        el: document.getElementById('player-root'),
        ...threekitConfig,
        onPreload: async () => {
          const fullConfiguration = getFullConfiguration();
          await Promise.all([
            initPresets(),
            handleResetConfiguration(fullConfiguration),
          ]);
        },
      });
      const translationData = getTranslations();
      setLanguageData(translationData);
    })();
  }, []);

  //Adding First Level to main attribute
  const addingLevelToMainAttribute = (attribute, subCatalogAttributes) => {
    const mainAttributes = contextApp.getPropConfiguration(
      'catalog-main-attribute'
    );
    mainAttributes[attribute] = subCatalogAttributes;

    contextApp.setAppConfiguration({
      'catalog-main-attribute': mainAttributes,
    });
  };

  const convert = (object, prefix = '') => {
    const result = {};
    for (const [key, value] of Object.entries(object)) {
      if (value.assetId) {
        const name = prefix ? prefix + '_' + key : key;
        result[name] = { assetId: value.assetId };
        if (value.configuration) {
          let valueNextLevel = value.configuration;
          Object.entries(convert(valueNextLevel, name)).forEach(
            ([key, value]) => (result[key] = value)
          );
        }
      }
    }
    return result;
  };

  // Get New Configuration
  const getNewConfiguration = (updatedConfiguration) => {
    const mainAttributes = contextApp.getPropConfiguration(
      'catalog-main-attribute'
    );
    for (var attribute in updatedConfiguration) {
      if (typeof updatedConfiguration[attribute] === 'string') {
        updatedConfiguration[attribute] = mainAttributes[attribute];
      }
    }
    return updatedConfiguration;
  };

  const handleSetAttribute = async (config) => {
    const updatedAttributes = { ...attributes };
    if (config.parent && config.firstLevel) {
      await toolkit.player.setConfiguration(
        { [config.firstLevel]: config.assetId },
        {
          name: config.parent.slice(config.parent.lastIndexOf('_') + 1),
          configurator: attributes[config.parent].configurator,
        }
      );
    } else if (config.parent && !config.firstLevel) {
      addingLevelToMainAttribute(config.parent, config.value);
      await toolkit.player.setConfiguration(
        { [config.parent]: config.assetId },
        null
      );
    } else {
      await toolkit.player.setConfiguration(config, null);
    }

    if (!updatedAttributes[config.value]) {
      let level = 1;
      let parent = config.parent;
      while (updatedAttributes[parent].parent) {
        level++;
        parent = updatedAttributes[parent].parent;
      }
      await window.threekitApi.api.scene.fetch(config.assetId);
      const subCatalog = window.threekitApi.api.scene.get({
        id: config.assetId,
      });
      const result = await toolkit.player.compileAttributesData(
        {},
        subCatalog.configurator.attributes
      );
      subCatalog.configurator.attributes.forEach((attr) => {
        if (result[attr.name]) {
          result[attr.name].parent = config.parent;
          result[attr.name].level = level;
          result[attr.name].configurator = updatedAttributes[config.parent]
            .configurator
            ? updatedAttributes[
                config.parent
              ].configurator.getNestedConfigurator({
                type: 'Asset',
                name: config.parent.slice(config.parent.lastIndexOf('_') + 1),
              })
            : window.threekitApi.api.player.configurator.getNestedConfigurator({
                type: 'Asset',
                name: config.parent,
              });
          const prefix = config.parent;
          const attrName = `${prefix}_${
            result[attr.name].configurator.attributes[0].name
          }`;
          config.attrName = attrName;
          updatedAttributes[attrName] = result[attr.name];
        }
      });
      setAttributes(updatedAttributes);
    }
    const updatedConfiguration = getFullConfiguration();
    await toolkit.template
      .prepTemplate(
        setContextByProp,
        contextApp.getPropConfiguration,
        updatedConfiguration,
        updatedAttributes,
        config
      )
      .then(async (result) => {
        await contextApp.setAppConfiguration(contextData);
        const templateData = result;
        setController(templateData);
        const config = convert(updatedConfiguration);
        await toolkit.player.getNewUpdateConfiguration(config);
        setConfiguration(getNewConfiguration(config));
        handleInputSelect();
      });
  };

  return (
    <Template
      title={title}
      subtitle={subtitle}
      controller={controller}
      configuration={configuration}
      languageData={languageData}
      dataTable={presetsData}
      handleResetConfiguration={handleResetConfiguration}
      handleSetAttribute={handleSetAttribute}
      threekitConfigByUrl={initialParameters}
    />
  );
};

export default TemplateContainer;
