import React, { useState } from 'react';
import { Wrapper } from './player.styles.js';
import cameraIcon from './../../../../assets/camera.svg';

const Player = () => {
  const [image, setImage] = useState('');

  const getSnapshotClick = async () => {
    const img = await window.threekitApi.api.snapshotAsync({
      size: { width: 1500, height: 1500 },
    });
    setImage(img);
  };

  return (
    <div>
      <div className="icon-container">
        <Wrapper id="player-root" />
        <a
          className="camera-icon"
          onClick={getSnapshotClick}
          href={image}
          download
        >
          <img src={cameraIcon} />
        </a>
      </div>
    </div>
  );
};

export default Player;
