export default {
  authToken: process.env.REACT_APP_AUTH_TOKEN,
  assetId: process.env.REACT_APP_ASSET_ID,
  orgId: process.env.REACT_APP_ORG_ID,
  defaultLocale: 'Neutral',
  env: process.env.REACT_APP_THREEKIT_ENV,
  currency: 'USD',
  pricebookName: 'US Dollars',
  showSIF: process.env.REACT_APP_SHOW_SIF,
  initialConfiguration: {},
};
