import React, { useState, useEffect, useCallback } from 'react';
import { Accordion1 as Accordion } from '../../Controllers';
import Player from '../SharedComponents/Player';
import Carousel from 'react-elastic-carousel';
import { Wrapper, PlayerWrapper, Title } from './accordionTemplate2.styles';
import threekitLogo from '../../../assets/Teknion-Logo-Grey.png';
import downloadIcon from '../../../assets/download-icon.svg';
import {
  pricebookService,
  getCatalogInfo,
  saveConfiguration,
  createOrder,
} from '../../../threekit/backend';
import { getFullConfiguration, getPrice } from '../../../threekit/player';

const AccordionTemplate2 = ({
  controller,
  configuration,
  dataTable,
  handleSetAttribute,
  handleResetConfiguration,
  languageData,
  threekitConfigByUrl,
}) => {
  const [activeAddress, setActiveAddress] = useState([0, 0]);
  const [price, setPrice] = useState(0);
  const [catalogInfo, setCatalogInfo] = useState({});
  const [ordeCodeArray, setOrdeCodeArray] = useState([]);
  const [dropdownStatus, setDropDownStatus] = useState(null);
  const [newConfig, setNewConfig] = useState(null);
  const [renderedItems, setRenderedItems] = useState([]);
  const [jsonArray, setJsonArray] = useState([]);
  const [initialPlayerConfig, setInitialPlayerConfig] = useState(null);
  const [hasInitialConfig, setHasInitialConfig] = useState(false);
  //const [currentPlayerConfig, setCurrentPlayerConfig] = useState(null);

  const breakPoints = [
    { width: 1, itemsToShow: 5 },
    { width: 550, itemsToShow: 3, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  const [windowWidth, setWindowWidth] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    (async () => {
      getCatalogInfo(
        threekitConfigByUrl.orgId,
        threekitConfigByUrl.assetId,
        threekitConfigByUrl.authToken
      ).then(async (res) => {
        if (res) {
          setCatalogInfo(res);
        }
      });
    })();
  }, []);

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  const saveConfigSIF = () => {
    var configId = '';
    var variant = getFullConfiguration();
    saveConfiguration(
      threekitConfigByUrl.authToken,
      threekitConfigByUrl.orgId,
      threekitConfigByUrl.assetId,
      variant
    ).then((res) => {
      if (res) {
        configId = res.id;
        callCreateOrder(configId);
      }
    });
  };

  const callCreateOrder = (configId) => {
    var orderId,
      sifUrl = '';
    createOrder(
      threekitConfigByUrl.authToken,
      configId,
      threekitConfigByUrl.orgId
    ).then((res) => {
      if (res) {
        orderId = res.id;
        sifUrl =
          'https://wzqlog0p3h.execute-api.ca-central-1.amazonaws.com/order/' +
          orderId +
          '/sif';
        window.location.assign(sifUrl);
      }
    });
  };
  const processPrice = () => {
    let pricebook = {};
    let pricebookId = '';

    pricebookService(
      threekitConfigByUrl.orgId,
      threekitConfigByUrl.authToken
    ).then((res) => {
      if (res) {
        res.pricebooks.forEach((item) => {
          if (item.name === threekitConfigByUrl.pricebookName) {
            pricebook = item;
          }
        });
      }
      pricebookId = pricebook?.id ? pricebook.id : '';
      const price = getPrice(pricebookId, threekitConfigByUrl.currency);
      price !== undefined
        ? setPrice(
            new Intl.NumberFormat(threekitConfigByUrl.locale, {
              style: 'currency',
              currency: threekitConfigByUrl.currency,
              minimumFractionDigits: 2,
            }).format(price)
          )
        : setPrice(0);
    });
  };

  useEffect(() => {
    if (newConfig !== null && dropdownStatus === true) {
      let keyConfig = newConfig ? Object.keys(newConfig) : null;
      if (keyConfig) {
        //setDropDownStatus(true);
        keyConfig.forEach((k) => {
          let id = newConfig[k].assetId;
          getElementById(id);
        });
      }
      setDropDownStatus(null);
    }
  });

  const convert = (object) => {
    for (const [key, value] of Object.entries(object)) {
      let element = {};
      element.key = key;
      if (value.assetId === undefined) {
        element.assetId = value.value.assetId;
      } else {
        element.assetId = value.assetId;
      }
      jsonArray.push(element);
      if (value.configuration === undefined) {
        if (value.value?.configuration !== undefined) {
          if (value.value.configuration !== '') {
            let valueNextLevel = value.value.configuration;
            convert(valueNextLevel);
          }
        }
      } else if (value.configuration !== '') {
        let valueNextLevel = value.configuration;
        convert(valueNextLevel);
      }
    }
  };

  const toObject = (arr) => {
    var rv = {};
    for (var i = 0; i < arr.length; ++i) rv[arr[i].key] = arr[i];
    return rv;
  };

  const carouselImageClick = (config) => {
    console.log('carousel click', config);
    handleResetConfiguration(config);
    window.threekitApi.configurator.setConfiguration(config);
    jsonArray.length = 0;
    convert(config);
    setNewConfig(toObject(jsonArray));
    // setDropDownStatus(true);
  };

  // Initialize configuration if necessary
  // Note: This initialization seems almost unnecessary, but the newConfig state object
  // appears as though it may be needed for something?
  if (!hasInitialConfig) {
    const playerConfig = getFullConfiguration();
    if (playerConfig) {
      convert(playerConfig);
      let config = toObject(jsonArray);
      // console.log('before set: ', JSON.parse(JSON.stringify(config)))
      setNewConfig(config);
      setHasInitialConfig(true);
    }
  }

  const getElementById = (id) => {
    let element = document.getElementById(id);
    if (element) {
      // console.log('Element with this Id exists=>', id, element);
      //element.click();
      let counter = 0;
      setRenderedItems({ [counter]: { assetId: id } });
      let keyConfig = Object.keys(newConfig)[0];
      let id = newConfig[keyConfig].assetId;
      if (renderedItems.length !== 0 && renderedItems[0].assetId) {
        let rendered = JSON.parse(JSON.stringify(renderedItems[counter]));
        if (rendered.assetId === id) {
          console.log('before config: ' + JSON.stringify(newConfig));
          let index = newConfig[keyConfig].assetId;
          if (index !== -1) {
            let temp = delete newConfig[keyConfig];
            setNewConfig(newConfig);
          }
          console.log('after config: ' + JSON.stringify(newConfig));
        }
        counter++;
      }
    } else {
      id && console.log('no element with this Id exists=>', id);
    }
  };

  var verticalOrientation = windowWidth <= 1366 ? false : true;

  processPrice();

  const newFormatOfAttributes = () => {
    let attributeWithValues = [];
    let sections = controller[0]?.sections[0]?.sections
      ? controller[0].sections[0].sections
      : [];
    let attributeSelected = window.attributeSelected;
    let attributeSelectedKey = attributeSelected
      ? Object.keys(attributeSelected)[0]
      : '';

    if (sections.length > 0) {
      sections.forEach((sectionItem) => {
        if (sectionItem && sectionItem.inputs.length > 0) {
          sectionItem.inputs.forEach((inputsItem) => {
            let values = [];
            if (
              inputsItem?.data?.options &&
              inputsItem.data.options.length > 0
            ) {
              inputsItem.data.options.forEach((valuesItem) => {
                values.push({
                  assetId: valuesItem.assetId,
                  code: valuesItem.code,
                  label: valuesItem.label,
                });
              });
            }
            attributeWithValues.push({
              attribute: inputsItem.attribute,
              values: values,
              parent: inputsItem.parent,
            });
          });
        }
      });
    }

    attributeWithValues.forEach(
      (attributeWithValuesItem, attributeWithValuesIndex) => {
        if (
          attributeWithValuesItem.parent !== '' &&
          attributeWithValuesItem.parent === attributeSelectedKey
        ) {
          attributeWithValues.splice(attributeWithValuesIndex, 1);
        }
      }
    );

    return attributeWithValues;
  };

  const getAssignedValue = (attr) =>
    attr?.value?.assetId
      ? attr.values.find((entry) => entry.assetId === attr.value.assetId)
      : null;

  const getOrderCodeString = () => {
    if (!window.threekitApi?.configurator) return;
    const player =
      window.threekitApi.api.player ||
      window.threekitApi.api.enableApi('player');
    const { configurator } = player;
    if (!configurator) return;
    const sections = controller[0]?.sections[0]?.sections;
    if (!sections) return;
    const configuration = configurator.getDisplayAttributes();
    const prefixCode = catalogInfo?.metadata?.itemId ?? '';
    let orderCode = prefixCode;
    for (const section of sections) {
      const sectionAttr = configuration.find(
        (attr) => attr.name === section.label
      );
      let localConfiguration = getAssignedValue(sectionAttr);
      let localConfigurator = configurator.getNestedConfigurator(sectionAttr);
      while (localConfigurator) {
        const code = localConfiguration?.metadata?.optionCode ?? '';
        orderCode += !code.startsWith('~') ? code : '';
        const tempAttr = localConfigurator.getDisplayAttributes()[0];
        localConfiguration = getAssignedValue(tempAttr);
        localConfigurator = localConfigurator.getNestedConfigurator(tempAttr);
      }
      const code = localConfiguration?.metadata?.optionCode ?? '';
      orderCode += !code.startsWith('~') ? code : '';
    }
    return orderCode;
  };

  return (
    <div>
      <div id="loader-container">
        <div className="lds-ellipsis"></div>
      </div>
      <div id="mainContainer" className="container">
        <div>
          <header>
            <img src={threekitLogo} className="header-logo" alt="" />
          </header>
        </div>
        <div className="player-container">
          <div className="threekit-player">
            <Player />
            <div className="tk-container-carousel">
              <div>
                {dataTable.length > 0 && (
                  <Carousel
                    breakPoints={breakPoints}
                    verticalMode={verticalOrientation}
                  >
                    {dataTable.map(({ config, thumbnailUrl }, i) => (
                      <div
                        key={i}
                        className="carousel-item-image"
                        onClick={() => carouselImageClick(config)}
                      >
                        <img src={thumbnailUrl} />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
          <div className="threekit-config">
            {controller?.[activeAddress[0]] && (
              <Title className="template-title">
                {controller[activeAddress[0]].label}
              </Title>
            )}
            {controller?.[activeAddress[0]] && (
              <div className="subtitle">
                {catalogInfo ? catalogInfo.description : ''}
              </div>
            )}

            <Accordion
              className="accordion-container"
              sections={
                controller[activeAddress[0]]?.sections?.[activeAddress[1]]
                  ?.sections
              }
              languageData={languageData}
              configuration={configuration}
              handleSetAttribute={handleSetAttribute}
              dropdownStatus={dropdownStatus}
            />
          </div>
        </div>
      </div>
      <footer>
        <span className="bottom-name">Order code: {getOrderCodeString()}</span>
        <div className="bottom-price">
          <span>{price}</span>
          <span className="sif">
            <a
              onClick={() => saveConfigSIF()}
              href="#"
              className={`${
                threekitConfigByUrl.showSIF ? 'show-sif' : 'hide-sif'
              }`}
            >
              <img src={downloadIcon}></img> SIF
            </a>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default AccordionTemplate2;
