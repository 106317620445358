import { fetchSavedConfiguration, getDataTableRows } from './backend';

/**
 * @desc To initialize the player we have to pass in an object
 *    with the the domain speicfic auth token, the assetId
 *    for the asset we're hoping to embed as well as the html
 *    div where the player is to be embedded.
 * @param object $initialSettings - object passed directly to threekitPlayer()
 * @return Promise - success or failure
 */
export async function initializePlayer(initialSettings) {
  const threekitConfig = {
    el: initialSettings.el,
    authToken: initialSettings.authToken,
    assetId: initialSettings.assetId,
    initialConfiguration: initialSettings.initialConfiguration,
  };
  const playerApi = await window.threekitPlayer({
    showShare: true,
    showLoadingThumbnail: true,
    ...threekitConfig,
  });

  /***** API SETUP START ***************************************************************/

  //  Enables access to the threekit player api
  playerApi.enableApi('player'); // exposes playerApi.player
  const configurator = await playerApi.getConfigurator();
  window.threekitApi = {
    api: playerApi,
    configurator,
  };
  /***** API SETUP END *****************************************************************/

  /***** PLAYER TOOLS START ************************************************************/
  //  The functional interactions with the player can be removed
  //  either individually or as an array

  playerApi.tools.removeTool('pan');

  //  playerApi.tools.removeTool(['pan', 'zoom']);
  /***** PLAYER TOOLS END **************************************************************/

  /***** PLAYER LIFECYCLE LISTENSERS START *********************************************/
  //  We add listeners to be triggered during the player's
  //  lifecycle events: PRELOADED, LOADED and RENDERED

  playerApi.on(playerApi.scene.PHASES.LOADED, async () => {
    initialSettings.onPreload
      ? initialSettings.onPreload()
      : console.log('Player has preloaded data');
  });

  // playerApi.on(playerApi.scene.PHASES.LOADED, () => {
  //  initialSettings.onLoad ? initialSettings.onLoad() : console.log('Player has loaded all data');
  // });

  //  playerApi.on(playerApi.scene.PHASES.RENDERED, () => {
  //      initialSettings.onRender ? initialSettings.onRender() : console.log('Player has rendered the default asset')
  //  });
  /***** PLAYER LIFECYCLE LISTENSERS END ***********************************************/
}

export async function getSnapshot() {
  return await window.threekitApi.api.snapshotAsync();
}

const PRESETS_METADATA_KEY = '_presetsDataTableId';
function getPresetsDataTableId() {
  const metadata = getAssetMetadata();
  if (!metadata) return;
  const datatableId = metadata.find(
    (metadata) => metadata.name === PRESETS_METADATA_KEY
  )?.defaultValue;
  return datatableId;
}

export async function getPresets(authToken) {
  const dataTableId = getPresetsDataTableId();

  if (dataTableId) {
    const rows = await getDataTableRows(dataTableId, authToken);
    if (rows) {
      return rows.map((row, index) => {
        const { configurationId, thumbnailUrl } = row.value;
        return { configurationId, thumbnailUrl };
      });
    }
  }

  return [];
}

export function getTranslations() {
  return window.threekitApi.api.getTranslations();
}

export function getFullConfiguration() {
  if (!window.threekitApi) return;
  return window.threekitApi.api.player.configurator.getFullConfiguration();
}

export function getPrice(pricebookId, currency) {
  if (!window.threekitApi) return;
  const price = window.threekitApi.configurator.getPrice(pricebookId, currency);
  return price;
}

export function getAssetMetadata(assetId) {
  if (!window.threekitApi || !window.threekitApi.configurator)
    throw new Error('threekitApi not setup');
  const nodeId = assetId || window.threekitApi.api.assetId;
  const metadata = window.threekitApi.api.scene.get({ id: nodeId }).configurator
    .metadata;
  return metadata;
}

export function getProductName() {
  if (!window.threekitApi || !window.threekitApi.configurator)
    throw new Error('threekitApi not setup');
  const productId = window.threekitApi.api.assetId;
  return window.threekitApi.api.scene.get({ id: productId }).name;
}
