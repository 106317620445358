/* eslint-disable no-unused-expressions */
import { validate as isUuid } from 'uuid';

/**
 *
 * @param {*} metadataMap
 * @param {*} subAttributes
 * @returns An object has entries of: Attribute / Subattribute name: { type, label, level, values:[] }
 */
const compileAttributesData = async (metadataMap = {}, subAttributes) => {
  if (!window.threekitApi || !window.threekitApi.configurator)
    throw new Error('threekitApi not setup');
  const output = {};
  const attrLevel = !subAttributes ? 0 : 1;
  const attributes = subAttributes
    ? subAttributes
    : await window.threekitApi.api.player.configurator.getVisibleAttributes();

  await Promise.all(
    attributes.map(async (attr) => {
      const existSpecialcode = attr.values.find(
        (value) => value.name === 'Special Code'
      )
        ? attr.values.find((value) => value.name === 'Special Code')
        : null;
      if (existSpecialcode) {
        var idx = attr.values.indexOf(existSpecialcode);
        attr.values.splice(idx, 1);
      }

      const existCustomersOwnMaterial = attr.values.find(
        (value) => value.name === "Customer's Own Material"
      )
        ? attr.values.find((value) => value.name === "Customer's Own Material")
        : null;
      if (existCustomersOwnMaterial) {
        var idx = attr.values.indexOf(existCustomersOwnMaterial);
        attr.values.splice(idx, 1);
      }

      const existSpecial = attr.values.find((value) => value.name === 'Special')
        ? attr.values.find((value) => value.name === 'Special')
        : null;
      if (existSpecial) {
        var idx = attr.values.indexOf(existSpecial);
        attr.values.splice(idx, 1);
      }

      const existCustomersOwnLeather = attr.values.find(
        (value) => value.name === "Customer's Own Leather"
      )
        ? attr.values.find((value) => value.name === "Customer's Own Leather")
        : null;
      if (existCustomersOwnLeather) {
        var idx = attr.values.indexOf(existCustomersOwnLeather);
        attr.values.splice(idx, 1);
      }

      if (!['Asset', 'String'].includes(attr.type)) return output;
      output[attr.name] = {
        type: attr.type,
        id: attr.id,
        label: attr.name,
        level: attrLevel,
      };
      if (attr.type === 'String') {
        output[attr.name].values = attr.values.map((val) => ({
          id: val.toLowerCase().replace(' ', '-'),
          value: val,
          label: val,
          level: attrLevel,
        }));
      }
      if (attr.type === 'Asset') {
        output[attr.name].values = await Promise.all(
          attr.values.map(async (val, index) => {
            const { name, configurator } = await window.threekitApi.api.scene
              .fetch(val.assetId)
              .then(() =>
                window.threekitApi.api.scene.get({ id: val.assetId })
              );
            const metadata = configurator.metadata;
            const imageUrl = metadata.find(
              (metadataItem) => metadataItem.name === '_UI_thumbnailUrl'
            )
              ? metadata.find(
                  (metadataItem) => metadataItem.name === '_UI_thumbnailUrl'
                ).defaultValue
              : '';
            const code = metadata.find(
              (metadataItem) => metadataItem.name === 'optionCode'
            )
              ? metadata.find(
                  (metadataItem) => metadataItem.name === 'optionCode'
                ).defaultValue
              : '';
            // Get _UI_label
            const uiLabel = metadata.find(
              (metadataItem) => metadataItem.name === '_UI_label'
            )?.defaultValue;

            if (name) {
              return {
                id: name.toLowerCase().replace(' ', '-'),
                assetId: val.assetId,
                value: uiLabel || name,
                label: uiLabel || name,
                image: imageUrl !== '' ? imageUrl : '',
                level: attrLevel,
                code: code ? code : '',
              };
            }
          })
        );
      } else {
        output[attr.name].values = await Promise.all(
          attr.values.map(async (val) => {
            if (!val || !val.assetId) return;
            const opt = {
              id: val.name.toLowerCase().replace(' ', '-'),
              value: val.assetId,
              label: val.name,
              level: attrLevel,
            };

            const dependency = new RegExp(/^_dependency/);
            const re = new RegExp(/^_/);
            const sceneApi = window.threekitApi.api.scene;
            let node = sceneApi.get({ id: val.assetId });
            if (!node) {
              await sceneApi.fetch(val.assetId);
              node = sceneApi.get({ id: val.assetId });
            }
            node.configurator.metadata.forEach((metadata) => {
              if (
                dependency.test(metadata.name) &&
                JSON.parse(metadata.defaultValue)
              )
                opt.dependencies = opt.dependencies
                  ? opt.dependencies.push(JSON.parse(metadata.defaultValue))
                  : [JSON.parse(metadata.defaultValue)];
              else if (metadata.name in metadataMap)
                opt[metadataMap[metadata.name]] = metadata.defaultValue;
              else if (re.test(metadata.name))
                opt[metadata.name.substring(1, metadata.name.length)] =
                  metadata.defaultValue;
            });
            return opt;
          })
        );
      }
    })
  );

  var outputs = Object.keys(output);
  if (outputs.length === 1) {
    var existSpecialcode = output[outputs[0]].values.find(
      (value) => value.value === 'Special Code'
    )
      ? output[outputs[0]].values.find(
          (value) => value.value === 'Special Code'
        )
      : null;
    if (existSpecialcode) {
      var idx = output[outputs[0]].values.indexOf(existSpecialcode);
      output[outputs[0]].values.splice(idx, 1);
    }
  }

  return output;
};

const getNewUpdateConfiguration = async (updatedConfiguration) => {
  for (var keyUpdatedConfiguration in updatedConfiguration) {
    updatedConfiguration[keyUpdatedConfiguration]?.assetId !== undefined &&
    updatedConfiguration[keyUpdatedConfiguration]?.assetId !== ''
      ? (updatedConfiguration[keyUpdatedConfiguration].name =
          await window.threekitApi.api.scene
            .fetch(updatedConfiguration[keyUpdatedConfiguration].assetId)
            .then(
              () =>
                window.threekitApi.api.scene.get({
                  id: updatedConfiguration[keyUpdatedConfiguration].assetId,
                }).name
            ))
      : null;
  }

  return updatedConfiguration;
};

const getVisibleAttributes = async () => {
  if (
    !window.threekitApi ||
    !window.threekitApi.configurator ||
    !window.threekitApi.api.player
  )
    throw new Error('threekitApi not setup');

  const visbleAttributes =
    window.threekitApi.api.player.configurator.getVisibleAttributes();
  return visbleAttributes;
};

const setConfiguration = async (config, parent) => {
  if (!config || !window.threekitApi) return;

  if (typeof config !== 'object') return;

  const keys = Object.keys(config);

  if (parent && parent.configurator) {
    const configuration = {
      [parent.name]: {
        assetId: config[keys[0]],
        type: 'item',
        configuration: '',
      },
    };
    await parent.configurator.setConfiguration(configuration);
  } else {
    const updateConfig = Object.entries(config).reduce(
      (output, [attribute, value]) =>
        Object.assign(output, {
          [attribute]: isUuid(value) ? { assetId: value } : value,
        }),
      {}
    );
    await window.threekitApi.configurator.setConfiguration(updateConfig);
  }

  if (!keys[0].includes('Lettering')) {
    document.getElementById('loader-container').style.display = 'block';
  }

  if (parent) {
    window.attributeSelected = { [parent.name]: config[keys[0]] };
  } else {
    window.attributeSelected = config;
  }
};

export default {
  compileAttributesData,
  setConfiguration,
  getNewUpdateConfiguration,
  getVisibleAttributes,
};
