import enum_inputs from '../../config/enum';
import { getAssetMetadata, getProductName } from '../../threekit/player';
export const prepTemplate = async (
  callbackSetContextByProp,
  callbackGetContextByProp,
  fullConfiguration,
  allAttributes,
  selectedSubcategory
) => {
  if (!window.threekitApi) return [];
  let sections = [];
  const metadata = getAssetMetadata();
  const productName = getProductName();
  let inputs = {};
  const template = [
    {
      label: productName,
      sections: [
        {
          label: '',
          sections: [],
        },
      ],
    },
  ];

  let attributes = Object.entries(allAttributes).reduce((acc, [key, value]) => {
    if (!value.level) {
      acc[key] = value;
    }
    return acc;
  }, {});

  // Attribute name : option name
  const mainAttributes =
    callbackGetContextByProp('catalog-main-attribute') || {};

  if (Object.keys(mainAttributes).length === 0) {
    const levels = Object.keys(attributes);
    for (const level of levels) {
      if (!attributes[level].level) {
        mainAttributes[level] = '';
      }
    }

    callbackSetContextByProp('catalog-main-attribute', mainAttributes);
  }

  const prepInput = (input) => {
    if (typeof input.data === 'object') return input;
    if (input.data === 'tk-platform') {
      if (!attributes[input.attribute]) return null;
      return {
        ...input,
        data: { options: attributes[input.attribute].values },
      };
    }
  };
  const prepSections = (sections) =>
    sections.reduce((output, section) => {
      const updatedSection = { ...section };
      if (section.inputs && section.inputs.length > 0) {
        updatedSection.inputs = section.inputs
          .map((inputId) => prepInput({ id: inputId, ...inputs[inputId] }))
          .filter((input) => !(input === null));
      }
      if (section.sections && section.sections.length > 0) {
        updatedSection.sections = prepSections(section.sections, inputs);
      }
      output.push(updatedSection);
      return output;
    }, []);

  const handleSubcategory = async (selectedSubcategory) => {
    const firstLevelAttributes = attributes;

    attributes = Object.assign({}, allAttributes);
    sections = callbackGetContextByProp('catalog-sections');
    inputs = callbackGetContextByProp('catalog-inputs');

    let keys = Object.keys(firstLevelAttributes);
    // Removing Disabled Attributes
    for (const key in attributes) {
      if (!keys.includes(key) && attributes[key].level === 0) {
        delete attributes[key];
      }
    }

    // Adding Enabled Attributes
    keys = Object.keys(attributes);
    for (const key in firstLevelAttributes) {
      if (!keys.includes(key)) {
        attributes[key] = firstLevelAttributes[key];
      }
    }

    return await window.threekitApi.api.scene
      .fetch(selectedSubcategory.assetId)
      .then(async () => {
        const attrName = selectedSubcategory.attrName;
        const subCatalogAttributes = allAttributes[attrName];
        const subCatalogMetadataArr = getAssetMetadata(
          selectedSubcategory.assetId
        );
        const subCatalogDisplayAs = subCatalogMetadataArr.find(
          (metadataItem) => metadataItem.name === '_UI_displayAttributesAs'
        );
        const displayAs =
          subCatalogDisplayAs &&
          JSON.parse(subCatalogDisplayAs['defaultValue']);
        if (
          subCatalogAttributes &&
          Object.keys(subCatalogAttributes).length !== 0
        ) {
          inputs[attrName] = {
            label: selectedSubcategory.value,
            attribute: attrName,
            type:
              displayAs &&
              displayAs[selectedSubcategory.value]?.type !== undefined
                ? enum_inputs[displayAs[selectedSubcategory.value].type]
                : enum_inputs['default'],
            data: 'tk-platform',
            cssClass: 'active',
            parent: selectedSubcategory.parent,
            options:
              displayAs &&
              displayAs[selectedSubcategory.value]?.options !== undefined
                ? displayAs[selectedSubcategory.value].options
                : {},
            firsrLevel:
              selectedSubcategory.firstLevel || selectedSubcategory.parent,
          };

          let subCategoryAdded = false;
          sections.map((section, index) => {
            const parent = selectedSubcategory.parent;
            const topLevel = selectedSubcategory.parent.split('_')[0];
            if (
              section.label === topLevel &&
              section.inputs.find((input) => input === parent) &&
              !subCategoryAdded
            ) {
              const parentIndex = section.inputs.indexOf(parent);
              if (
                parentIndex !== -1 &&
                section.inputs.length > 1 &&
                parentIndex !== section.inputs.length - 1
              ) {
                section.inputs.length = parentIndex + 1;
              }
              section.inputs.push(attrName);
              subCategoryAdded = true;
            }
          });
        }
        template[0].sections[0].sections = sections;

        callbackSetContextByProp(
          'catalog-sections',
          template[0].sections[0].sections
        );
        callbackSetContextByProp('catalog-inputs', inputs);
        return prepSections(template);
      });
  };

  if (selectedSubcategory) {
    return handleSubcategory(selectedSubcategory);
  } else {
    const newMetadata = metadata.find(
      (metadataItem) => metadataItem.name === '_UI_displayAttributesAs'
    )
      ? JSON.parse(
          metadata.find(
            (metadataItem) => metadataItem.name === '_UI_displayAttributesAs'
          )['defaultValue']
        )
      : null;

    let exist;
    for (const keyNameAttributes in attributes) {
      exist = false;
      for (const keyNameMetadata in newMetadata) {
        if (keyNameAttributes === keyNameMetadata) {
          exist = true;
        }
      }
      if (inputs[keyNameAttributes] === undefined) {
        inputs[keyNameAttributes] = {
          label: keyNameAttributes,
          attribute: keyNameAttributes,
          type: exist
            ? enum_inputs[newMetadata[keyNameAttributes].type]
            : attributes[keyNameAttributes].values.length > 2
            ? enum_inputs.default
            : enum_inputs.button,
          data: 'tk-platform',
          cssClass: 'active',
          options: exist ? newMetadata[keyNameAttributes].options : {},
          parent: '',
          firsrLevel: '',
        };
      }
    }

    for (const keyInputs in inputs) {
      template[0].sections[0].sections.push({
        label: inputs[keyInputs].label,
        inputs: [inputs[keyInputs].attribute],
      });
    }

    callbackSetContextByProp(
      'catalog-sections',
      template[0].sections[0].sections
    );
    callbackSetContextByProp('catalog-inputs', inputs);

    let result = prepSections(template);

    const handleSubConfiguration = async (
      parent,
      assetId,
      config,
      firstLevel
    ) => {
      const subcategoryName = Object.keys(config)[0];
      const subcategory = {
        firstLevel: firstLevel === parent ? '' : firstLevel,
        [parent]: subcategoryName,
        assetId,
        parent,
        value: subcategoryName,
      };
      result = await handleSubcategory(subcategory);
      const { assetId: subAssetId, configuration } = config[subcategoryName];
      if (configuration) {
        await handleSubConfiguration(
          subcategoryName,
          subAssetId,
          configuration,
          firstLevel
        );
      }
    };

    for (const attrName of Object.keys(fullConfiguration)) {
      const { configuration, assetId } = fullConfiguration[attrName];
      if (configuration) {
        await handleSubConfiguration(
          attrName,
          assetId,
          configuration,
          attrName
        );
      }
    }
    return result;
  }
};
export default { prepTemplate };
