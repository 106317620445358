import React, { useEffect, useState, useContext } from 'react';
import {
  Wrapper,
  ButtonsRow,
  ButtonWrapper,
} from './radioButtonsImages.styles';
import icnImgMissing from '../assets/no-image.webp';
import ReactTooltip from 'react-tooltip';

import AppContext from '../../Context/AppContext';

const RadioButtonsImages = ({ options, onChange, selected }) => {
  const contextApp = useContext(AppContext);

  const [select, setSelect] = useState(null);

  useEffect(() => {
    setSelect(selected);
  }, [selected]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [options]);

  if (!options || options.length === 0) return null;

  const handleSelectChange = (assetId, assetName) => {
    const isExclusion = ['Pants', 'Jacket', 'Athletix'].includes(assetName);
    const storageItem = isExclusion
      ? { key: 'gearSelected', item: assetName }
      : { key: 'isClickEvent', item: true };

    contextApp.setAppConfiguration({
      [storageItem.key]: storageItem.item,
    });

    onChange(assetName);

    setSelect(assetId);
  };

  const cleanToolTip = (str) => {
    const mapObj = {
      '-': ' ',
      _: ' ',
    };
    str = str.replaceAll(/_|-/g, function (matched) {
      return mapObj[matched];
    });
    return str;
  };

  return (
    <Wrapper>
      <ButtonsRow className="button-row">
        {options.map(({ label, image, value: assetName, assetId }, index) => {
          return (
            <ButtonWrapper
              id={assetId}
              key={index}
              selected={select === assetId}
              onClick={(_) => handleSelectChange(assetId, assetName)}
              data-tip={cleanToolTip(label)}
              className="button-wrapper"
            >
              <div>
                <img
                  className={image ? '' : 'hide-img'}
                  src={image || icnImgMissing}
                  alt={label}
                ></img>
              </div>
            </ButtonWrapper>
          );
        })}
      </ButtonsRow>
      <ReactTooltip
        place="top"
        type="dark"
        effect="solid"
        className="custome-theme"
      />
    </Wrapper>
  );
};

export default RadioButtonsImages;
