import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ButtonWrapper = styled.div`
  width: max-content;
  margin: 8px auto;
  padding: 8px 12px;
  cursor: pointer;

  font-weight: ${(props) => (props.selected ? 600 : 400)};
  background: ${(props) => (props.selected ? '#F69362;' : 'none;')};
  ${(props) => props.selected && `color: grey;`};

  & > div:nth-child(1) {
    img {
      ${(props) => (props.selected ? `height: 40px;` : `height: 100%`)}
      ${(props) => (props.selected ? `width: 40px;` : `width: 100%;`)}
      object-fit: contain;
    }
  }

  & > div:nth-child(2) {
    width: 100px;
    text-align: center;
    padding-top: 8px;
    font-size: 13px;
  }
`;
