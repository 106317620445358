import React, { useEffect, useState } from 'react';
import { Templates } from './toolkit';
import templateData from './config/template';
import threekitConfig from './config/threekitConfig';
import AppContext from './toolkit/Context/AppContext';

const App = () => {
  const [initConf, setInitConf] = useState({
    menuSelected: 'materials-0',
    gearSelected: 'Athletix',
    clickOnPrimary: 'false',
    isClickEvent: 'false',
    gearSelected: '',
    'catalog-sections': null,
    'catalog-inputs': null,
    'catalog-attributes': null,
    'catalog-first-levels': null,
    'catalog-main-attribute': null,
  });

  const getDataByProp = (nameProp) => {
    return initConf[nameProp];
  };

  useEffect(() => {
    window.addEventListener('mousedown', function (e) {
      if (e.button == 1) {
        e.preventDefault();
      }
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        appConfiguration: initConf,
        setAppConfiguration: (config) => {
          setInitConf(Object.assign(initConf, config));
        },
        getPropConfiguration: getDataByProp,
      }}
    >
      <Templates template={templateData} threekitConfig={threekitConfig} />
    </AppContext.Provider>
  );
};

export default App;
