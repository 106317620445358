import axios from 'axios';

export const fetchTranslation = async (
  method = 'get',
  url,
  headers = '',
  body = ''
) => {
  var config = {
    method: method,
    url: url,
    headers: headers,
    body: body,
  };

  return axios(config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return false;
    });
};

export const pricebookService = async (
  orgId,
  token,
  method = 'get',
  headers = '',
  body = ''
) => {
  var config = {
    method: method,
    url: `https://preview.threekit.com/api/orgs/${orgId}/pricebooks?all=true&bearer_token=${token}`,
    headers: headers,
    body: body,
  };

  return axios(config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return false;
    });
};

export const getCatalogInfo = async (
  orgId,
  assetId,
  token,
  method = 'get',
  headers = '',
  body = ''
) => {
  var config = {
    method: method,
    url: `https://preview.threekit.com/api/assets/${assetId}?orgId=${orgId}&bearer_token=${token}`,
    headers: headers,
    body: body,
  };

  return axios(config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return false;
    });
};

export const fetchSavedConfiguration = (configId, token) => {
  const config = {
    method: 'get',
    url: `https://preview.threekit.com/api/configurations/${configId}?bearer_token=${token}`,
  };

  return axios(config)
    .then((res) => {
      // console.log('fetchSavedConfiguration res =>', res);
      return res.data;
    })
    .catch((error) => {
      console.log('fetchSavedConfiguration res =>', error);
      return false;
    });
};

export const getDataTableRows = async (tableId, token) => {
  const config = {
    method: 'get',
    url: `https://preview.threekit.com/api/datatables/${tableId}/rows?all=true&bearer_token=${token}`,
  };

  return axios(config)
    .then((response) => {
      // console.log('getDataTableRows res =>', response.data.rows);
      return response.data.rows;
    })
    .catch((error) => {
      // console.log('getDataTableRows error =>', error);
      console.log(error);
    });
};

export const getAsset = (assetId, orgId, token) => {
  const config = {
    method: 'get',
    url: `https://preview.threekit.com/api/assets/${assetId}?orgId=${orgId}&bearer_token=${token}`,
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const createOrder = async (token, configId, orgId) => {
  var config = {
    method: 'post',
    url: `https://preview.threekit.com/api/orders?bearer_token=${token}`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      name: 'sif Download',
      originOrgId: '',
      platform: {
        id: '',
        platform: '',
        storeName: '',
      },
      metadata: {},
      items: [
        {
          id: configId,
          count: 1,
        },
      ],
      derivative: {},
      status: 'List',
      orgId: orgId,
    },
  };

  return axios(config)
    .then((res) => {
      console.log('saveOrder res =>', res);
      return res.data;
    })
    .catch((error) => {
      console.log('saveOrder res =>', error);
      return false;
    });
};

export const saveConfiguration = async (token, orgId, productId, variant) => {
  var config = {
    method: 'post',
    url: `https://preview.threekit.com/api/configurations?bearer_token=${token}`,
    headers: { 'content-type': 'application/json' },
    data: {
      orgId: orgId,
      productId: productId,
      productVersion: 'v1',
      variant: variant,
      metadata: {},
    },
  };

  return axios(config)
    .then((res) => {
      console.log('saveConfiguration res =>', res);
      return res.data;
    })
    .catch((error) => {
      console.log('saveConfiguration res =>', error);
      return false;
    });
};
